//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$hr-opacity: 1;
$gold-color: #D4AF37;
$silver-color: #ACAEA9;
$bronze-color: #CD7F32;
