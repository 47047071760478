//
// user.scss
// Use this to write your custom SCSS
//

html, body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  position: relative;
}

.me-n3 {
  margin-right: -0.75rem!important;
}

.dropdown-toggle.dropdown-ellipses {
  padding-left: 10px;
  padding-right: 10px;
}

.gold-trophy  {
  color: $gold-color;
}

.silver-trophy  {
  color: $silver-color;
}

.bronze-trophy  {
  color: $bronze-color;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #4f98ff;
  border-color: #4f98ff; /*set the color you want here*/
}
